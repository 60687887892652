<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">录入</el-button>
				<el-button type="primary" @click="batch_edit">批量修改</el-button>
				<el-button type="primary" @click="make_csv">模板下载</el-button>
			</el-form-item>
		</el-form>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="tord" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" ref='multipleTable'>
				<el-table-column label="运单编号" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货物名称">
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.cargo_name" placeholder="货物名称" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="发货重量" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.cargo_weight_start" placeholder="发货重量" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="到货重量" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.cargo_weight_arrived" placeholder="到货重量" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="货物重量" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.cargo_weight" placeholder="货物重量" clearable></el-input>
					</template>
				</el-table-column>

				<el-table-column label="发货联系人">
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.case_link_man" placeholder="发货联系人" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="发货联系电话" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.case_link_tel" placeholder="发货联系电话" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="到货联系人" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.aim_link_man" placeholder="到货联系人" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="到货联系人" >
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.aim_link_tel" placeholder="到货联系人" clearable></el-input>
					</template>
				</el-table-column>
			</el-table> 
		 </div>
	    <!-- 回到顶部 -->
        <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>
		<!-- 分页 -->
		<!-- <el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 50, 100, 200]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination> -->


	</div>
</template>

<script>
	export default {
		components:{
		},
		data() {
			return {

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				tord:[],
			}
		},
		created() {

		},
		methods: {
			make_csv(){
				//生成下载链接
				let link = document.createElement('a');
				link.href = 'https://zwbapi.jungong56.com/app/res/files/template/act.xlsx';

				//对下载的文件命名
				link.download = "act.xlsx";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				
				// this.truck_tord_nums=[];
			},
			//批量修改提交
			batch_edit(){
				if(this.tord.length ==0){
					this.$my.other.msg({
						type:'info',
						str:'请先选择数据'
					 })
					 return
				}
				let truck_tord_list  = []
				//遍历数据
				this.tord.forEach(item=>{
					truck_tord_list.push({
						truck_tord_num:item.truck_tord_num,
						cargo_weight_start:item.cargo_weight_start,
						cargo_weight_arrived:item.cargo_weight_arrived,
						cargo_weight:item.cargo_weight,
						cargo_name:item.cargo_name,

						case_link_man:item.case_link_man,
						case_link_tel:item.case_link_tel,
						aim_link_man:item.aim_link_man,
						aim_link_tel:item.aim_link_tel,
					})
				})
				//修改数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_edit_by_sub_user',
						truck_tord_list,
					},callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'编辑成功'
					    })
						this.tord = []
					}
				})
			},
			//录入功能
            page_ser(){
                this.$my.excle.importExcel((data, dataRef) => {
					if(data.length>1000){
						this.$my.other.msg({
							type:'info',
							str:'最大录入量为1000条,请修改后重新录入'
					    })
						return
					}
					var tels = []
					var tord_list = []
					//获取对应位置下标
					var truck_tord_num_index = data[0].indexOf('运单编号')
					var cargo_name_index = data[0].indexOf('货物名称')
					var cargo_weight_start_index = data[0].indexOf('发货重量')==-1? data[0].indexOf('发货重量(吨)'): data[0].indexOf('发货重量')
					if(cargo_weight_start_index==-1){
						cargo_weight_start_index = data[0].indexOf('发货重量 (吨)')
					}
					var cargo_weight_arrived_index = data[0].indexOf('到货重量')==-1? data[0].indexOf('到货重量(吨)'): data[0].indexOf('到货重量')
					if(cargo_weight_start_index==-1){
						cargo_weight_start_index = data[0].indexOf('到货重量 (吨)')
					}
					var cargo_weight_index = data[0].indexOf('货物重量')==-1? data[0].indexOf('货物重量(吨)'): data[0].indexOf('货物重量')
					if(cargo_weight_start_index==-1){
						cargo_weight_start_index = data[0].indexOf('货物重量 (吨)')
					}
					var case_link_man_index = data[0].indexOf('发货联系人')
					var case_link_tel_index = data[0].indexOf('发货联系电话')
					var aim_link_man_index = data[0].indexOf('到货联系人')
					var aim_link_tel_index = data[0].indexOf('到货联系电话')
					//删除表头
					delete data[0]
					data.forEach((item,index)=> {
						tord_list.push({
							truck_tord_num:item[truck_tord_num_index],
							cargo_name:item[cargo_name_index],
							cargo_weight_start:item[cargo_weight_start_index],
							cargo_weight_arrived:item[cargo_weight_arrived_index],
							cargo_weight:item[cargo_weight_index],

							case_link_man:item[case_link_man_index],
							case_link_tel:item[case_link_tel_index],
							aim_link_man:item[aim_link_man_index],
							aim_link_tel:item[aim_link_tel_index],
						})
					});
					this.list.total =tord_list.length
					this.tord = tord_list
					// this.$my.net.req({
					// 	data:{
					// 		mod:'app_user',
					// 		ctr:'user_list_by_app_user',
					// 		tels,
					// 		num:1000
					// 	},callback:(data)=>{
					// 		var data_info = []
					// 		data.list.forEach(item=>{
					// 			if(!data_info[item.tel]){
					// 				data_info[item.tel]=[]
									
					// 			}
					// 			data_info[item.tel].push(item)
					// 		})
					// 		//console.log(data_info)
					// 		tord_list.forEach(item=>{
					// 			// console.log(item)
					// 			item.truck_owner_info=data_info[String(item.tel).trim()]
					// 			var user_info =  data_info[String(item.tel).trim()]
					// 			if(!data_info[String(item.tel).trim()]){
					// 				return
					// 			}
					// 			if(data_info[String(item.tel).trim()].length ==1){
					// 				item.user_num =user_info[0].user_num
					// 			}
					// 		})
					// 		this.list.total =tord_list.length
					// 		this.tord = tord_list
					// 	}
					// })

                });
            },
			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
				
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: 100%;
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height: 700px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>